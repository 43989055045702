<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>教务中心</a-breadcrumb-item>
      <a-breadcrumb-item>待排班学员</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-box">
      <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item>
              <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="search">
          <a-form layout='inline' @submit="searchList">
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>学员名称/手机号</span>
                </template>
                <a-input v-model.trim="searchData.fuzzy" placeholder="学员名称/手机号" style="width: 160px"/>
              </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
            </a-form-item>
            <a-form-item>
              <a-popover placement="bottom" trigger="click" v-model="visible">
                <template slot="content">
                  <div class="more-search">
                    <a-form layout='inline'>
                      <a-form-item class="block-line" label="所属校区" >
                        <a-select v-model="searchData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' placeholder="请选择">
                          <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="课程顾问" > 
                        <a-select v-model="searchData.course_consultant_id" placeholder="请选择">
                          <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="来源渠道" >
                        <a-tree-select
                            v-model="searchData.channel_id"
                            :tree-data="parentChannels"
                            tree-checkable
                            allowClear
                            treeNodeFilterProp="title"
                            :maxTagCount='1'
                            :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                            :show-checked-strategy="SHOW_ALL"
                            placeholder="请选择来源渠道"
                            @change='onChangeCascader'
                        />
                      </a-form-item>
                      <a-form-item class="block-line" label="咨询状态" >
                        <a-select v-model="searchData.user_wechat" placeholder="请选择">
                          <a-select-option v-for="(d, index) of userWechat" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-form>
                    <div class="more-search__buttons">
                      <a-button type="dashed" @click="closeSearch">取消</a-button>
                      <a-button @click="reset">重置</a-button>
                      <a-button type="primary" @click="searchList">搜索</a-button>
                    </div>
                  </div>
                </template>
                <a-button>更多<a-icon type="down" /></a-button>
              </a-popover>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="student_id"
          :columns="columns" :dataSource="list" :scroll="{ x: 1300 }">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="user" slot-scope="text,record">
            <LUser @eventSon="toUpdataL" :data="record"/>
          </template>
          <template slot="action" slot-scope="text,record">
            <a href="javascript:;" @click="toeditModal(record)">业务办理</a>
          </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    </div>
    <editModal v-if="editModalVisible" :tab="1" :item="edModalData"/>
  </div>
</template>

<script>
const columns = [ 
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '学员名称', width: '150px', dataIndex: 'student_name', key: 'name', fixed: 'left', scopedSlots: { customRender: 'user' } },
  { title: '手机号', dataIndex: 'cellphone', key: 'age'},
  { title: '签约校区', dataIndex: 'studio_id', key: '1' },
  { title: '课程顾问', dataIndex: 'cc_id', key: '2' },
  { title: '课程科目', dataIndex: 'course_cate_id', key: '5' },
  { title: '剩余课时', dataIndex: 'left_times', key: '6' },
  { title: '剩余赠课', dataIndex: 'left_gift_times', key: '7' },
  { title: '报班数', dataIndex: 'student_class_member', key: '8' },
  { title: '学员卡数', dataIndex: 'student_classes', key: '9' },
  { title: '上课次数', dataIndex: 'times', key: '10' },
  { title: '请假次数', dataIndex: 'student_cancel_classes ', key: '11' },
  { title: '消费次数', dataIndex: 'student_cost_times', key: '12' },
  { title: '消费金额', dataIndex: 'student_costs', key: '13' },
  { title: '来源渠道', dataIndex: 'student_channel_id', key: '14' },
  { title: '状态', dataIndex: 'student_course_status', key: '15' },
  { title: '操作', key: 'operation', fixed: 'right', width: 80, scopedSlots: { customRender: 'action' }}
]

import C_ITEMS from '@/utils/items'
// import refundModal from './refundModal'
import editModal from '@/views/students/zcxy/editModal'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'Contract',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    editModal
    // refundModal
  },

  data() {
    return {
      loading: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      userWechat: C_ITEMS.userWechat,
      studios: [],
      consultants: [],
      channels: [],
      channelsId:[],
      parentChannels:[],
      searchData: {},
      editModalVisible:false,
      visible: false,
      refundVisible: false,
    }
  },
  mixins:[tableMixins],
  created() {
    this.getStudio()
    this.getConsultant()
    this.getSourceChannel()
  },

  methods: {

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchStudioAction', {})
      this.studios = res.data
    },

    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', {})
      this.consultants = res.data
    },

    async getSourceChannel() {
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
      this.parentChannels = res.data
    },

    async getList() {
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_at') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('penddingAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    toUpdataL(){
      this.getList()
    },
    showRefundModal(item) {
      this.modalData = item
      this.refundVisible = true
    },
    hideRefundModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.refundVisible = false
    },
    toeditModal(item){
      this.edModalData = {student_id: item.student_id,student_name:item.student_name}
      this.showEditModal()
    },
    showEditModal() {
      this.editModalVisible = true
    },
    hideModal() {
      this.editModalVisible = false
    },
  }
}
</script>